// TO DO - Split up into separate files per modules


/* Menu */
$eagle-eye-dark-blue: #273D56;
$eagle-eye-light-blue: #A0ADBB;
$eagle-eye-highlight-blue: #4aade5;
$eagle-eye-subtitle-color: rgba(255, 255, 255, 0.8);

$main-loading-spinner-color: white;
$main-fab-bg-gradient: linear-gradient(45deg, #3AA0AA, #584784);
$main-fab-bg-disabled-color: #CCCCCC;

$ee-tooltip-bg-color: rgba(#092039,.9);
$ee-tooltip-text-color: #FFFFFF;

$menu-bg-color: #44444A;
$menu-bg-gradient: linear-gradient(#3CB5C5, #594481);

$menu-tile-default-color: white;
$menu-tile-hover-color: rgba(255, 255, 255, 1);
$menu-tile-selected-color: rgba(255, 255, 255, 1);
$menu-tile-selected-bg-color: rgba(255, 255, 255, 0.05);
$menu-tile-selected-border-color: rgba(255, 255, 255, 1);

$map-menu-button-select-strip-color: #F2C94C;

$page-header-bg-color: #24374C;
$page-header-text-color: #FFFFFF;

$page-header-user-info-color: rgba(255, 255, 255, 0.8);

$content-panel-header-bg-color: #44444B;
$content-panel-header-text-color: #2A81BD;
$content-panel-bg-color: #FFFFFF;
$content-panel-button-container-bg-color: #DCDFE2;
$content-panel-group-heading-text-color: #8898AA;

$content-fields-bg-color: #F6F9FC;
$content-fields-border-color: rgba(136, 152, 170, 0.4);
$content-fields-text-color: #2E4866;
$content-fields-none-text-color: #cccccc;
$content-fields-bg-edit-color: #FFFFFF;
$content-fields-border-edit-color: rgba(136, 152, 170, 0.4);
$content-fields-focus-drop-shadow-color: rgba(74, 173, 229, 0.8);

$content-group-frame-color: rgba(0,0,0,0.1);
$content-group-none-text-color: $eagle-eye-light-blue;

$buttons-key-action-bg-color: #2A81BD;
$buttons-key-action-text-color: #FFFFFF;
$buttons-key-action-border-color: #2A81BD;
$buttons-frame-bg-color: #2A81BD;
$buttons-frame-text-color: #2A81BD;
$buttons-frame-border-color: #2A81BD;
$buttons-warn-bg-color: rgba(187, 0, 0, 0.16);
$buttons-warn-text-color: #bb0000;
$buttons-warn-border-color: #bb0000;

$pa-annotation-popup-bg-color: #333333;

$map-search-bg-color: rgba(17, 30, 45, 0.5);
$map-search-text-color: #FFFFFF;
$map-search-icon-color: #FFFFFF;
$map-search-clear-color: #E5E5E5;
$map-search-placeholder-color: #CCCCCC;
$map-basemap-label-color: rgba(255, 255, 255, 0.8);
$map-panel-bg-color: #ffffff;

$map-ref-search-border-color: #467D9B;
$map-ref-search-icon-color: #467D9B;
$map-ref-search-text-color: #273D56;
$map-ref-search-placeholder-color: #8898AA;
$map-ref-search-clear-color: #666666;
$map-ref-loading-spinner-color: #34B7B0;

$map-measure-panel-bg-color: #FFFFFF;
$map-measure-title-bg-color: #47B0E7;

$map-street-view-no-image-bg-color: #000000;
$map-street-view-no-image-text-color: #FFFFFF;

$map-panel-close-icon-color: #8898AA;
$map-panel-drop-shadow-color: rgba(#273D56, 0.25);
$map-panel-drop-shadow-color-light: rgba(#273D56, 0.1);


/* Map Controls */
$map-controls-accent-color: #2E4866;

/* Toolbar and toolbar panels */
$map-toolbar-primary-action-button-color: #47B0E7;
$map-toolbar-primary-action-button-hover-color: #2A81BD;
$map-toolbar-primary-action-icon-color: #FFFFFF;

/* Instructions panel */
$map-instructions-icon-color: white;
$map-instructions-panel-bg-color: #092039;

/* Notifications panel */
$notification-panel-success-bg-color: #72B671;
$notification-panel-error-bg-color: #CC5E55;
$notification-panel-info-bg-color: #43B4EC;

/* Map Legend Panel */
$icon-non-gov-schools-bg-color: #0252BA;
$icon-non-gov-schools-border-color: #003880;
$icon-non-gov-schools-icon-color: #FCFCFC;
$map-legend-list-border-color: #E5EAF1;

/* Dialog */
$dialog-title-color: #2A81BD;


$tooltip-alert-color: #E64173;
