@import 'ee-colors';
@import 'sizes';
@import 'mixins/panels';
@import 'animations';

@import 'hub/_mat-overrides.scss';
@import 'admin/_mat-overrides.scss';
@import 'vision/mat-overrides';
@import 'storm/storm-mat-overrides';

// TO DO - Split up into separate files per modules


mat-radio-group {
  .mat-radio-container, .mat-radio-outer-circle, .mat-radio-inner-circle {
    width: 18px !important;
    height: 18px !important;;
  }

  .mat-radio-checked .mat-radio-outer-circle {
    border-color: #2E4866 !important;
  }

  .mat-radio-inner-circle {
    background-color: #2E4866 !important;
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.45);
  }
}

.mat-select-panel {
  .toggle-button {
    padding: 8px;
    font-size: 12px;
    background-color: rgba(0,0,0,0.03);
    font-weight: 600;
    cursor: pointer;
    color: #2A81BD;
  }
}

.mat-option {
  &.mat-selected:not(.mat-option-multiple) {
    background: rgba(0, 0, 0, 0.04) !important;
  }

  &.mat-selected:not(.mat-option-disabled) {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .mat-option-text {
    font-family: 'Open Sans', sans-serif;

    .mat-icon {
      font-size: 16px !important;
      width: 16px;
      height: 16px;
    }
  }
}

.admin-button.mat-button {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-paginator-page-size-label,
.mat-paginator-range-label {
  font-family: 'Open Sans', sans-serif;
}

.mat-dialog-container {
  padding: 18px !important;
  overflow-x: hidden !important;
}

.cdk-global-scrollblock {
  overflow: hidden !important;
}

.mat-form-field {
  font-family: 'Nunito', sans-serif !important;
}

.mat-form-field.pa-map-annotation {
  width: 100%;

  .mat-form-field-wrapper {
    width: 100%;
    padding-bottom: 0 !important;

    .mat-form-field-infix {
      width: 100%;
      border-top: none;
      font-size: 14px;

      textarea {
        font-family: 'Open Sans', sans-serif !important;
        width: 100%;
        resize: none;
        line-height: 1.5;
        color: white;
        border-bottom: 2px solid transparent;

        &:not(:disabled) {
          border-bottom: 2px solid white;
        }
      }

    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.measure-panel-modes {
  max-height: 300px !important;

  .mat-option {
    .mat-option-text {
      img {
        width: 18px;
        position: relative;
        top: 4px;
        margin-right: 4px;
      }
    }

    .mat-icon {
      width: 18px;
      height: 18px;
      font-size: 16px;
      margin-right: 4px;
    }
  }
}

.measure-panel {
  .mat-tab-header {
    height: 32px;

    .mat-tab-label {
      height: 32px !important;
      font-size: 12px !important;
    }
  }
}



.notification-container {
  .mat-select-value {
    font-size: 12px !important;
    font-family: 'Nunito', sans-serif !important;
  }
}

.map-date-selector {
  .mat-button {
    padding: 0 5px !important;
    min-width: 30px !important;
  }

  .mat-button-wrapper {
    min-width: 40px;
    width: 40px;
    color: #2E4866;
    padding: 0;
    box-shadow: none;
    line-height: 32px;
  }

  .mat-select-arrow-wrapper {
    vertical-align: baseline !important;
  }

  .mat-icon {
    font-size: 14px !important;
    vertical-align: baseline !important;
  }

  .mat-form-field {
    max-width: 120px !important;
  }

  .mat-form-field-ripple {
    height: 0px !important;
    background-color: white !important;
  }

  .mat-select {
    margin-top: 3px !important;
  }

  .mat-select-value {
    text-align: center !important;
  }

  .mat-select-value-text {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 11px !important;
    vertical-align: middle !important;
  }

  .mat-option {
    font-size: inherit !important;
    font-family: inherit !important;
  }

  .mat-form-field-underline {
    height: 0px !important;
  }
}

.mat-form-field.measure-panel {
  &.mat-form-field-disabled {
    .mat-form-field-wrapper {
      background-color: $content-fields-bg-color;
      border-color: $content-fields-border-color;

      input {
        color: $content-fields-text-color !important;
      }
    }
  }

  &.mat-focused {
    .mat-form-field-wrapper {
      border: 1px solid #4aade5 !important;
      box-shadow: 0 0 4px rgba(74, 173, 229, 0.8);
    }
  }

  .mat-form-field-wrapper {
    padding: 0;
    background-color: $content-fields-bg-edit-color;
    border: 1px solid $content-fields-border-edit-color;
    border-radius: 3px;

    .mat-form-field-infix {
      border-top: none;
      font-size: 11px;
      width: auto;
      padding: 4px 0;

      .mat-select {
        font-family: 'Nunito', sans-serif !important;
        line-height: 15px;
        padding: 0 4px;
        width: calc(100% - 8px);

        .mat-select-trigger {
          width: 100%;
          display: inline-block;
          vertical-align: top;

          .mat-select-value {
            max-width: none;
            width: calc(100% - 18px);
            display: inline-block;
          }

          .mat-select-arrow-wrapper {
            width: 18px;
            display: inline-block;
            vertical-align: top;
            margin-top: 6px;
          }
        }
      }

      input {
        line-height: 15px;
        font-family: 'Nunito', sans-serif !important;
        padding: 0 8px;
        width: calc(100% - 24px);
      }
    }

    .mat-form-field-suffix {
      position: absolute;
      bottom: 6px;
      right: 0;
    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.mat-form-field.pa-detail {
  vertical-align: top;
  display: inline-block;

  .mat-error {
    &.div {
      margin-top: 3px !important;

      > div {
        position: absolute;
      }
    }

    font-size: 10px;
    margin-top: 8px !important;
  }

  .error {
    color: #f44336;
    font-size: 10px;
    position: absolute;
    margin-top: 2px;
  }

  &.mat-focused {
    .mat-select {
      box-shadow: 0 0 4px $content-fields-focus-drop-shadow-color !important;
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-wrapper {
      padding-bottom: 12px;
    }
  }

  .mat-form-field-wrapper {
    width:  calc(100% - 10px);
    overflow: visible;
    padding: 0;
    margin: 0;

    .mat-form-field-subscript-wrapper .mat-error {
      margin-top: 9px;
    }

    .mat-form-field-infix {
      border-top: none;
      font-size: 11px;

      .mat-select {
        background-color: $content-fields-bg-edit-color;
        border: 1px solid $content-fields-border-edit-color;
        border-radius: 3px;
        font-family: 'Open Sans', sans-serif !important;
        line-height: 15px;
        padding: $content-panel-field-inner-padding;
        width: calc(100% - 2 * #{$content-panel-field-inner-padding} - 2px);

        &:focus {
          border: 1px solid #4aade5 !important;
          box-shadow: 0 0 4px rgba(74, 173, 229, 0.8);
        }

        &.mat-select-disabled {
          background-color: $content-fields-bg-color;
          border-color: $content-fields-border-color;

          &:focus {
            border-color: rgba(136, 152, 170, 0.4) !important;
            box-shadow: none !important;
          }

          .mat-select-value {
            color: $content-fields-text-color;
          }
        }

        .mat-select-trigger {
          width: 100%;

          .mat-select-value {
            max-width: none;
            width: calc(100% - 18px);
          }

          .mat-select-arrow-wrapper {
            width: 18px;
          }
        }
      }

      input, textarea {
        background-color: $content-fields-bg-edit-color;
        border: 1px solid $content-fields-border-edit-color;
        border-radius: 3px;
        line-height: 15px;
        font-family: 'Open Sans', sans-serif !important;
        padding: $content-panel-field-inner-padding;
        width: calc(100% - 2 * #{$content-panel-field-inner-padding} - 2px);

        &:focus {
          border: 1px solid #4aade5 !important;
          box-shadow: 0 0 4px rgba(74, 173, 229, 0.8);
        }

        &:disabled {
          background-color: $content-fields-bg-color;
          border-color: $content-fields-border-color;
          color: $content-fields-text-color;
        }
      }

      textarea {
        resize: none;
      }
    }

    .mat-form-field-suffix {
      .mat-icon {
        margin-left: $content-fields-suffix-margin;
        font-size: 100%;
        cursor: pointer;
      }
    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.comments-container {
  width: 100%;

  .mat-card {
    box-shadow: none !important;
    padding: 0;
    margin: 16px;

    &:not(:first-child) {
      margin-top: 0;
    }

    .comment-button {
      width: 24px;
      height: 24px;
      line-height: 24px;

      .mat-icon {
        font-size: 16px;
        color: $content-fields-text-color;
      }
    }

    &.new-comment {
      margin-bottom: 0;

      .comment-button {
        margin-top: 8px;
        margin-left: 4px;
      }

      .group {
        margin: 0 !important;
        width: 100%;
      }
    }

    .edit-button {
      display: none;
    }

    &:hover {
      .edit-button {
        display: block;
      }
    }

    .mat-card-header-text {
      margin: 0;
      width: 100%;

      .mat-card-title {
        font-family: 'Open Sans', sans-serif !important;
        font-weight: 700;
        color: $content-fields-text-color;
        font-size: 11px;
        width: 100%;
        margin-bottom: 4px;
        line-height: 24px;
        min-height: 24px;

        .timestamp {
          margin-left: 4px;
          font-weight: 500;
          color: $eagle-eye-light-blue;
        }
      }

      //.mat-card-subtitle {
      //  font-size: 11px;
      //  margin-bottom: 4px !important;
      //}
    }
  }

  .mat-form-field.pa-detail .mat-form-field-wrapper {
    padding: 0;
    width: 100%;

    textarea {
      max-height: 96px;
    }
  }
}

.mat-form-field.map-search {
  width: calc(100% - 32px - 32px - 4px);
  margin-right: 4px;
  font-size: 16px;

  .mat-form-field-wrapper {
    width: 100%;
    padding-bottom: 0 !important;

    .mat-form-field-infix {
      width: 100%;
      border-top: none;

      input {
        color: $map-search-text-color;
      }

      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          font-size: 13px;
          font-family: 'Open Sans', sans-serif !important;
          line-height: 25px;
          color: $map-search-placeholder-color !important;
        }
      }
    }

    .mat-form-field-prefix {
      padding-right: $map-search-button-padding-h;

      .mat-icon {
        cursor: pointer;
        color: $map-search-icon-color;
        font-size: $map-search-icon-size;
      }
    }

    .mat-form-field-suffix {
      .mat-icon {
        cursor: pointer;
        position: relative;
        top: $map-search-clear-v-margin;
        color: $map-search-clear-color;
        font-size: $map-search-clear-icon-size;
        width: $map-search-clear-icon-size;
        height: $map-search-clear-icon-size;
      }

      .spinner {
        position: relative;
        top: 3px;
        width: $map-search-clear-icon-size;
        height: $map-search-clear-icon-size;

        .mat-progress-spinner {
          width: 100% !important;
          height: 100% !important;

          > svg {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.map-ref-layer-title .fa-times {
  color: $eagle-eye-light-blue;
  font-size: 18px !important;
  float: right;
  text-align: right !important;
}

.mat-form-field.admin-date {
  &.mat-form-field-disabled {
    .mat-form-field-wrapper {
      background-color: $content-fields-bg-color;
      border-color: $content-fields-border-color;

      input {
        color: $content-fields-text-color !important;
      }
    }
  }

  &.mat-focused {
    .mat-form-field-wrapper {
      border: 1px solid #4aade5 !important;
      box-shadow: 0 0 4px rgba(74, 173, 229, 0.8);
    }
  }

  .mat-form-field-wrapper {
    padding: 0;
    background-color: $content-fields-bg-edit-color;
    border: 1px solid $content-fields-border-edit-color;
    border-radius: 4px;

    .mat-form-field-infix {
      border-top: none;
      font-size: 11px;
      width: auto;
      padding: 7.5px 0;

      input {
        line-height: 15px;
        font-family: 'Open Sans', sans-serif !important;
        padding: 0 8px;
        width: calc(100% - 24px);
        margin: 0;
      }
    }

    .mat-form-field-suffix {
      position: absolute;
      bottom: 2px;
      right: 0;
    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.mat-form-field.ref-search {
  width: calc(100% - 2 * #{$map-ref-search-padding-h});
  border: 1px solid $eagle-eye-light-blue;
  padding: 0 $map-ref-search-padding-h;
  border-radius: 4px;
  height: 32px;

  input {
    vertical-align: middle;
  }

  .mat-form-field-wrapper {
    width: 100%;
    padding-bottom: 0 !important;

    .mat-form-field-infix {
      width: 100%;
      border-top: none;

      input {
        color: $map-ref-search-text-color;
        font-size: 11px;
        font-family: 'Open Sans', sans-serif !important;
        line-height: 20px;
        margin-top: -1px;
      }

      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          font-size: 11px;
          font-family: 'Open Sans', sans-serif !important;
          line-height: 32px;
          color: $map-ref-search-placeholder-color !important;
        }
      }
    }

    .mat-form-field-prefix {
      padding-right: $map-search-button-padding-h;

      .mat-icon {
        color: $map-ref-search-border-color !important;
      }
    }

    .mat-form-field-suffix, .mat-form-field-prefix {
      cursor: pointer;

      .mat-icon {
        position: relative;
        top: $map-search-clear-v-margin;
        color: $map-ref-search-clear-color;
        font-size: $mat-small-button-icon-width;
      }
    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.mat-form-field.search-filter {
  width: 200px;
  border: 1px solid $eagle-eye-light-blue;
  padding: 0 $map-ref-search-padding-h;
  border-radius: 4px;
  height: 25px;

  input {
    vertical-align: middle;
  }

  .mat-form-field-wrapper {
    width: 100%;
    padding-bottom: 0 !important;

    .mat-form-field-infix {
      width: 100%;
      border-top: none;

      input {
        color: $map-ref-search-text-color;
        font-size: 11px;
        font-family: 'Open Sans', sans-serif !important;
        line-height: 11px;
        margin-top: -8px;
      }

      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          font-size: 11px;
          font-family: 'Open Sans', sans-serif !important;
          line-height: 25px;
          color: $map-ref-search-placeholder-color !important;
        }
      }
    }

    .mat-form-field-prefix {
      padding-right: $map-search-button-padding-h;

      .mat-icon {
        color: $map-ref-search-border-color !important;
      }
    }

    .mat-form-field-suffix, .mat-form-field-prefix {
      cursor: pointer;

      .mat-icon {
        position: relative;
        top: -3px;
        color: $map-ref-search-clear-color;
        font-size: $mat-small-button-icon-width;
      }
    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.mat-autocomplete-panel.admin-search-autocomplete {
  .mat-option {
    font-size: 11px !important;
    height: 32px !important;

    .user-group {
      background-color: #27D973;
      padding: 2px 4px;
      border-radius: 4px;
      font-size: 9px;
      font-weight: 600;
      margin-left: 2px;
      color: white;
    }
  }
}

.mat-chip.admin-chip {
  min-height: 28px;
  font-size: 11px;
  color: #2E4866 !important;
  background-color: #cfd6e1 !important;
  font-weight: 600;

  &.group {
    background-color: #27D973 !important;
    color: white !important;
  }

  &:hover::after {
    opacity: 0.025 !important;
  }

  .mat-icon {
    font-size: 12px !important;
    height: 12px !important;
    width: 12px !important;
  }
}

.mat-form-field.admin-input {

  &.mat-form-field-disabled {
    background-color: $content-fields-bg-color;
    border-color: $content-fields-border-color;

    .mat-form-field-wrapper {
      input {
        color: $content-fields-text-color !important;
      }
    }
  }

  input {
    vertical-align: middle;
  }

  .mat-form-field-wrapper {
    width: 100%;
    padding-bottom: 0 !important;

    .mat-form-field-infix {
      width: 100%;
      border-top: none;
      padding: 0 !important;

      input, .mat-select {
        border: 1px solid $content-fields-border-edit-color;
        padding: 0 $map-ref-search-padding-h;
        //padding: 15px 8px 5px 5px;
        border-radius: 4px;
        height: 32px;
        background-color: white;
        box-sizing: border-box;
        font-size: 11px;
        color: $map-ref-search-text-color;
        font-family: 'Open Sans', sans-serif !important;

        &[disabled] {
          opacity: 0.4;
        }
      }

      input {
        line-height: 32px;
      }

      .mat-select {
        line-height: 30px;

        .mat-select-value {
          color: $map-ref-search-text-color;
        }
      }

      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          margin-top: -1px;
          margin-left: 8px;
          font-size: 11px;
          font-family: 'Open Sans', sans-serif !important;
          line-height: 32px;
          color: $map-ref-search-placeholder-color !important;
        }
      }
    }

    .mat-form-field-prefix {
      padding-right: $map-search-button-padding-h;

      .mat-icon {
        color: $map-ref-search-border-color !important;
      }
    }

    .mat-form-field-suffix, .mat-form-field-prefix {
      cursor: pointer;

      .mat-icon {
        position: relative;
        color: $eagle-eye-light-blue;
        font-size: 13px;
        opacity: 0.6;
      }
    }

    .mat-form-field-suffix {
      position: absolute;
      right: 6px;
      top: 6px;
    }

    .mat-form-field-subscript-wrapper {
      position: relative;
      margin: 0;

      .mat-error {
        margin-top: 4px;
      }
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.mat-checkbox.admin-check {
  font-size: 12px;

  &.fixed {
    min-width: 84px;
  }
}

.mat-checkbox {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
  border-radius: 1px;
}

.mat-raised-button {
  line-height: 1 !important;
  padding: 6px 16px !important;
  min-height: 32px;
  height: 32px !important;
  min-width: 80px !important;
  box-shadow: none !important;
  border-radius: 4px !important;;
  font-weight: 600 !important;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;

  &.key-action {
    &.icon-button {

      padding: 4px 8px !important;

      &.dropdown {
        padding: 4px !important;
        min-width: 0 !important;

        .mat-button-wrapper .mat-icon {
          margin-right: 0 !important;
        }
      }

      &.download {
        .mat-button-wrapper .mat-icon {
          margin-right: 0 !important;
          margin-left: 8px !important;
          width: 12px;
          height: 12px;
          font-size: 12px;
          line-height: 14px;
        }
      }

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .mat-icon {
          margin-right: 8px !important;
        }
      }
    }

    &:disabled {
      opacity: 0.3;
    }

    /* Fake disabled class */
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    background: $buttons-key-action-bg-color !important;
    color: $buttons-key-action-text-color !important;
    border: 2px solid $buttons-key-action-border-color;
  }

  &.key-action-frame {
    &:disabled {
      opacity: 0.3;
    }

    background-color: transparent !important;
    border: 2px solid $buttons-frame-border-color;
    color: $buttons-frame-text-color !important;
    height: auto;
    border-radius: 2px;
  }

  &.green {
    background-color: #27D973 !important;
    border-color: #27D973;
  }

  &.red {
    background-color: $tooltip-alert-color !important;
    border-color: $tooltip-alert-color;
  }

  &.scary-red {
    background-color: #BB0A33 !important;
    border-color: #BB0A33 !important;
  }

  > span {
    .mat-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  &.mat-warn {
    border: 2px solid $buttons-warn-border-color;
    background: $buttons-warn-bg-color !important;
    color: $buttons-warn-text-color !important;
  }
}

.mat-mini-fab {
  width: 32px !important;
  height: 32px !important;

  .mat-button-wrapper {
    padding: 0 !important;
    line-height: 16px !important;

    .mat-icon {
      font-size: 16px;
      margin-top: -6px;
    }
  }
}

.mat-progress-spinner.notification-update-spinner {
  width: 32px !important;
  height: 32px !important;;

  > svg {
    width: 100% !important;;
    height: 100% !important;;

    circle {
      stroke: rgb(71, 176, 231);
    }
  }
}

.mat-progress-spinner.main-loading {
  > svg {
    > circle {
      stroke: $main-loading-spinner-color;
    }
  }
}

.mat-progress-spinner.map-loading {
  > svg {
    width: $map-loading-spinner-size !important;
    height: $map-loading-spinner-size !important;
    filter: drop-shadow(0 0 1px rgba(255,255,255, 1));

    > circle {
      stroke: $map-controls-accent-color;
    }
  }
}

.mat-progress-spinner.ref-loading {
  width: $map-ref-loading-spinner-size !important;
  height: $map-ref-loading-spinner-size!important;

  > svg {
    width: $map-ref-loading-spinner-size !important;
    height: $map-ref-loading-spinner-size !important;

    > circle {
      stroke: $map-ref-loading-spinner-color;
    }
  }
}

.mat-progress-spinner.hub-table-loading {
  width: 72px !important;
  height: 72px !important;

  > svg {
    width: 72px !important;
    height: 72px !important;

    > circle {
      stroke: #2E4866;
      opacity: 0.6;
    }
  }
}

.mat-progress-spinner.pa-details-loading {
  width: $pa-field-data-loading-spinner-size !important;
  height: $pa-field-data-loading-spinner-size!important;;

  > svg {
    width: $pa-field-data-loading-spinner-size !important;
    height: $pa-field-data-loading-spinner-size !important;

    > circle {
      stroke: $map-ref-loading-spinner-color;
    }
  }
}

.mat-icon-button.small-button {
  width: $mat-small-button-width;
  height: $mat-small-button-width;
  line-height: $mat-small-button-width;

  .mat-icon {
    font-size: $mat-small-button-icon-width;
  }
}

.mat-autocomplete-panel.map-search-autocomplete {
  min-width: $map-search-autocomplete-panel-width;
  width: auto;
  height: auto;
  max-height: calc(100vh - 100px);
  margin-top: $map-search-autocomplete-panel-top-margin;

  &.small-map {
    max-height: calc(515px - 80px) !important;
  }

  .map-search-scroll-container {
    position: relative;
    height: 100%;
  }

  .mat-optgroup {
    .mat-optgroup-label {
      font-family: 'Nunito', sans-serif !important;
      font-size: 12px;
      font-weight: bold;
      color: $eagle-eye-dark-blue;
      text-align: left;
      height: 40px;
      line-height: 40px;

      &::before {
        font-family: 'Font Awesome 6 Pro', sans-serif;
        margin-right: 8px;
        text-align: left;
        vertical-align: top;
        font-size: $map-search-result-icon-size;
        min-width:$map-search-result-icon-size;
        width: $map-search-result-icon-size;
      }
    }

    &.marker {
      .mat-optgroup-label::before {
        content: '\f3c5';
      }
    }

    &.vector {
      .mat-optgroup-label::before {
        content: '\f5ef';
      }
    }

    &.school {
      .mat-optgroup-label::before {
        content: '\f549';
      }
    }
  }

  .mat-option {
    height: 28px;
    line-height: 1;

    &:hover {
      background: #EBEFF5;
    }

    .mat-option-text {
      color: $eagle-eye-dark-blue;
      text-align: left;
      text-overflow: unset;
      width: 100%;
      position: relative;

      .name, .id {
        display: inline-block;
        font-family: 'Open Sans', sans-serif !important;
      }

      .name {
        width: calc(100% - 60px - 8px);
        margin-right: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 28px;
        vertical-align: top;
        font-size: 11px;
      }

      .id {
        background-color: rgba($eagle-eye-dark-blue, 0.4);
        color: white;
        border-radius: 2px;
        text-align: center;
        padding: 4px 8px;
        font-size: 8px;
        margin-top: 6px;
        position: absolute;
        right: 0;
      }
    }

    &.mat-active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.mat-expansion-panel {
  box-shadow: none !important;

  .mat-list-text {
    padding-left: $mat-ref-list-item-padding-h !important;
  }

  .mat-expansion-indicator::after {
    border-width: 0 1px 1px 0;
    padding: 2px;
    border-color: $eagle-eye-light-blue;
    margin-top: -4px;
  }

  .mat-pseudo-checkbox {
    width: 14px;
    height: 14px;
    border: 1.5px solid $eagle-eye-light-blue;
  }

  .mat-pseudo-checkbox:after {
    border-width: 1.5px;
    width: 8px;
    height: 3px;
    margin-top: -0.5px;
    transition: none;
  }

  .mat-pseudo-checkbox-checked {
    background: $eagle-eye-highlight-blue !important;
  }
}

.mat-primary .mat-pseudo-checkbox-checked {
  background: $eagle-eye-highlight-blue !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.mat-fab {
  &:not([disabled]) {
    background: $main-fab-bg-gradient !important;
  }

  &[disabled] {
    background-color: $main-fab-bg-disabled-color !important;
  }
}

.mat-selection-list {
  padding-top: 0 !important;
}

.mat-selection-list.advanced-search {
  .mat-list-option {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 11px;
    height: 32px;
    padding: 0 8px;
    width: calc(100% - 16px);

    .mat-list-item-content {
      padding: 0;

      .mat-pseudo-checkbox-checked {
        background-color: #4aade5;
      }
    }
  }
}

.toolbar-panel {
  .mat-expansion-panel-body {
    padding: 0 0 8px 16px !important;

    .mat-list-item-content {
      padding: 4px !important;
    }
  }

  .mat-expansion-panel-header {
    .mat-expansion-indicator {
      margin-right: 2px;
    }
  }
}

.mat-menu-content {
  padding: 4px 0 !important;
}

.mat-menu-item {
  font-family: 'Nunito', sans-serif !important;
  color: $eagle-eye-dark-blue !important;
  height: 32px !important;
  line-height: 32px !important;

  &[disabled] {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.25) !important;

    .mat-icon {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  &:hover {
    background: #EBEFF5 !important;
  }

  .mat-icon {
    font-size: 16px !important;
    line-height: 24px !important;
    color: $eagle-eye-dark-blue !important;
    margin-right: 8px !important;
    vertical-align: middle !important;
  }

  span {
    line-height: 24px !important;
    font-size: 12px;
    font-weight: 600;
    vertical-align: middle;
    max-width: 190px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-menu-ripple .mat-ripple-element {
    background-color: rgba(0, 0, 0, 0);
  }
}

.map-panel {
  .mat-tab-group {
    .mat-tab-body {
      padding: 8px;
    }
  }

  .tab-content {
    position: relative;
    max-height: calc(100vh - 164px);
  }

  .mat-accordion {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        padding: 0 4px;
        height: 32px !important;

        font-family: 'Nunito', sans-serif !important;
        font-weight: 700;
        color: $content-panel-header-text-color;
        font-size: 12px;

        .mat-expansion-panel-header-title {
          width: calc(100% - 32px);
        }

        &[aria-disabled='true'] {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }

      .mat-expansion-panel-content {
        font-family: 'Open Sans', sans-serif !important;
        font-size: 12px;

        .mat-expansion-panel-body {
          padding: 0 0 8px 16px;
        }
      }
    }
  }
}


.mat-tab-group {
  height: 100%;
  font-family: 'Nunito', sans-serif !important;

  .mat-tab-header {
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            font-family: 'Nunito', sans-serif !important;
            font-weight: 700;
            color: $content-panel-header-text-color;
            font-size: 13px;
            line-Height: 18px;
            height: 42px;
            opacity: 1;
            padding: 0 20px;
            min-width: auto;

            .mat-tab-label-content {
              .mat-icon {
                height: $tab-group-icon-size;
                font-size: $tab-group-icon-size;
                margin-right: $tab-group-icon-margin;
              }
            }

            &.mat-tab-disabled {
              color: #cccccc !important;
            }
          }
        }

        .mat-ink-bar {
          background-color: #2A81BD;
        }
      }
    }
  }

  .mat-tab-body-content {
    overflow: hidden;
  }
}

.mat-menu-panel {
  min-height: 0 !important;

  &:not(.user-menu):not(.status-menu):not(.notification-menu):not(.measure-panel-menu) {
    margin-top: -36px;
  }

  &.vision-custom-filter {
    overflow: hidden;
    width: 200px;
    margin-top: 0 !important;

    .mat-menu-content {
      padding: 8px !important;

      .vision-input {
        width: calc(100% - 2 * 8px - 1px);
      }
    }
  }

  &.documents-menu .mat-menu-item {
    height: auto !important;
    white-space: initial;
    padding-top: 4px;
    padding-bottom: 4px;

    span {
      line-height: 1.5 !important;
    }
  }

  &.user-menu, &.documents-menu {
    margin-left: 3px;
    border-radius: 0 0 4px 4px;

    .mat-menu-content {
      .menu-divider {
        width: 100%;
        height: 1px;
        background-color: #eee;
        margin: 4px 0;
      }

      .mat-menu-item.report-highlight {
        background-color: rgba(230, 65, 115, 0.25);

        > span {
          font-weight: bold;
        }
      }
    }

    &.sub-menu {
      border-radius: 4px;
    }
  }

  &.status-menu {
    border-radius: 0 0 4px 4px;
  }

  &.gap-menu {
    margin-top: 14px;
    margin-right: -6px;
    
    .header-name {
      height: 24px !important;
      margin-left: 8px;
      margin-right: 8px;
    }

    .menu-label {
      font-size: 10px;
      font-weight: bold;
      font-family: 'Open Sans', sans-serif;
      color: #273D56;
    }
  }

  &.gap-menu, &.measure-panel-menu {
    .zhuri-logo {
      > svg {
        width: $pa-download-button-icon-size;
        height: $pa-download-button-icon-size;
        padding: 4px;
      }
    }
  }

  &.notification-menu {
    max-width: 400px !important;
    width: 350px !important;
    overflow-x: hidden !important;

    .hide-checkbox .mat-checkbox-inner-container {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }

    .notification-menu-scroll-container {
      position: relative;
      max-height: 50vh !important;
    }

    .mat-menu-content {
      padding: 0 !important;
    }

    .header-name {
      height: 24px !important;
      margin-left: 8px;
      margin-right: 8px;
    }

    .menu-label {
      font-size: 10px;
      font-weight: bold;
      font-family: 'Open Sans', sans-serif;
      color: #273D56;
    }

    .count-badge {
      margin-left: 12px;

      &.unread .mat-badge-content {
        background-color: orange !important;
      }

      .mat-badge-content {
        border-radius: 20% !important;
        color: white;
        background-color: #A7B3C1 !important;
        font-family: 'Open Sans', sans-serif !important;
      }
    }


    .mark-link {
      color: #2196f3;
      font-size: 10px;
      margin-right: 10px;
      cursor: pointer;
    }

    .notification-container {
      max-width: 100% !important;

      .mat-menu-item {
        overflow-wrap: break-word !important;
        font-family: 'Open Sans', sans-serif !important;
        height: auto !important;
        line-height: normal !important;
        font-size: 10px !important;
        width: 100%;
        padding: 0 12px;

        .notification-main {
          padding-top: 8px !important;
          padding-bottom: 8px !important;

          .notification-icon-unread {
            max-width: 32px;
            font-weight: bold;

            .notification-unread-icon {
              color: inherit !important;
            }

            .mat-icon:hover {
              color: #41b94f !important;
            }
          }

          .notification-icon-read {
            max-width: 32px;

            .notification-read-icon {
              color: inherit !important;
            }
          }

          .notification-content {
            max-width: calc(100% - 32px);
            width: 100% !important;

            .notification-content-columns {
              width: 100% !important;

              .notification-title {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-family: 'Nunito', sans-serif !important;
                width: 100% !important;
                font-size: 12px !important;
                font-weight: bold;
                color: #8898AA;
              }
            }

            .summary {
              margin-top: 2px !important;
              white-space: pre-wrap !important;
              font-family: 'Open Sans', sans-serif !important;
              font-size: 11px !important;
            }

            .view-detail {
              margin-top: 4px;
              font-family: 'Nunito', sans-serif !important;
              color: #2E4866;
              font-size: 11px;
              font-weight: bold;
            }

            .summary, .view-detail {
              &.hover {
                color: #8898AA;
              }
            }
          }
        }

        .notification-time {
          color: #8898AA;
          text-align: right;
          font-family: 'Open Sans', sans-serif;
          font-size: 10px;
          width: 100% !important;
        }

      }

    }

  }
}

.mat-button-toggle-label-content {
  padding: 0 !important;
}

  .toolbar .mat-button:hover {
    background: #EBEFF5;

    .mat-button-focus-overlay {
      background: transparent;
    }
  }

.toolbar-annotation-menu {
  position: absolute;
  right: calc(100% + #{$map-toolbar-arrow-width} + 1px);
  overflow: visible !important;

  &::before {
    content: '';
    position: absolute;
    right: $map-toolbar-arrow-right;
    top: 12px;
    width: 0;
    height: 0;
    border: $map-toolbar-arrow-width solid transparent;
    border-left: $map-toolbar-arrow-width solid #fff;
    z-index: 3;
  }
}

.toolbar-travel-tools {
  position: absolute;
  right: calc(100% + #{$map-toolbar-arrow-width} + 1px);
  overflow: visible !important;

  &::before {
    content: '';
    position: absolute;
    right: $map-toolbar-arrow-right;
    top: 12px;
    width: 0;
    height: 0;
    border: $map-toolbar-arrow-width solid transparent;
    border-left: $map-toolbar-arrow-width solid #fff;
    z-index: 3;
  }

  button {
    color: $map-controls-accent-color;
    height: $map-toolbar-width;
    line-height: $map-toolbar-width;

    .mat-icon {
      color: $map-controls-accent-color;
    }
  }
}

.user-info {
  .mat-button-focus-overlay,
  .mat-button-ripple .mat-ripple-element {
    background-color: rgba(0, 0, 0, 0);
  }
}

.map-control-panel {
  button {
    .mat-button-focus-overlay,
    .mat-button-ripple,
    .mat-ripple-element {
      border-radius: 0;
      background: none;
    }
  }
}

.map-legend {
  &.small-map {
    .map-legend-container .mat-expansion-panel-body .mat-list-base {
      max-height: calc(515px - 120px) !important;
    }
  }
}

.toolbar {
  &.small-map {
    .toolbar-panel {
      .mat-accordion {
        max-height: calc(515px - 190px) !important;
      }
    }
  }
}

.map-legend-container {

  .mat-expansion-panel-body {
    padding: 0 !important;

    .mat-list-base {
      max-height: calc(100vh - 160px);
      width: 100%;
      position: relative;
      padding: 0 !important;
    }
  }

  .mat-expansion-panel-header {
    .mat-expansion-indicator {
      margin-right: 3px;

      &::after {
        border-width: 0 2px 2px 0;
        padding: 3px;
      }
    }

    &.mat-expanded {
      .mat-expansion-indicator {
        transform: rotate(0) !important;
      }
    }

    &:not(.mat-expanded) {
      .mat-expansion-indicator {
        transform: rotate(180deg) !important;
      }
    }
  }

  .mat-list-item[disabled] .mat-list-item-content {
    opacity: 0.25;
  }
}


.mat-list-item.legend-item {
  padding: 0 $map-legend-panel-padding 0 4px;
  height: 35px !important;
  overflow: hidden !important;
  position: relative;

  &:hover {
    cursor: grab;
  }

  &.gu-mirror {
    width: 260px !important;
    background-color: white;
    cursor: grab;

    > * {
      pointer-events: none;
    }

    .map-legend-hide-button {
      display: none;
    }

    .map-legend-delete-button {
      display: none;
    }
  }

  &:not(.gu-mirror) {
    border-top: 1px solid $map-legend-list-border-color;
  }

  &.disabled {
    .mat-list-item-content {
      opacity: 0.25;
    }
  }

  .map-legend-button {
    width: auto;
    height: 24px;
    opacity: 0.6;

    &.show-on-hover {
      visibility: hidden;
    }

    .mat-icon {
      font-size: 12px;
      width: 24px;
      height: 24px;
      display: block;
      color: $eagle-eye-dark-blue;
    }

    &.zoom-warning {
      .mat-icon {
        color: $tooltip-alert-color;
      }

      &.visible {
        visibility: visible;
      }

      &:not(.visible) {
        visibility: hidden;
      }
    }

    &.filter {
      opacity: 0.8;

      &.visible {
        visibility: visible;
      }

      &:not(.visible) {
        visibility: hidden;
      }

      &.off {
        opacity: 0.4;
      }
    }

    &.palette-visible {
      opacity: 0.6;
      visibility: visible;

      .mat-icon {
        background: -webkit-linear-gradient(0deg ,#ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    .map-legend-button.show-on-hover {
      visibility: visible;
    }
    .map-legend-grip-handle {
      visibility: visible;
    }
  }

  .map-legend-grip-handle {
    //cursor: grab;
    visibility: hidden;

    .mat-icon {
      color: #ccc;
      font-size: 10px;
      width: 14px;
      line-height: 24px;
    }
  }

  .map-legend-details {
    width: 178px;
    padding-left: $map-legend-panel-padding;
    overflow: hidden;

    p {
      font-family: 'Nunito', sans-serif;
      color: $eagle-eye-dark-blue;
      font-size: 12px;
      font-weight: bold;
      width: 100%;
      display: inline;
      cursor: pointer;
      float: left;

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .stream-icon {
        font-size: 16px;
        width: 18px;
        height: 16px;
        margin-left: 6px;
        color: #2E4866;
        -webkit-animation: fadeinout 6s infinite;
        animation: fadeinout 6s infinite;
      }

      .tooltip-icon {
        width: 12px;
        height: 12px;
        font-size: 12px;
        margin-left: 4px;
        color: #8898AA;
        opacity: 0.7;
      }

      .warning-icon {
        width: 14px;
        height: 12px;
        font-size: 12px;
        margin-left: 4px;
        color: $tooltip-alert-color;
      }
    }
  }

  .icon-container {
    height: $map-legend-icon-container-size;
    width: $map-legend-icon-container-size;
    border-radius: $map-legend-icon-border-radius;
    display: block;

    .mat-icon {
      font-size: $map-legend-icon-font-size;
      color: $icon-non-gov-schools-icon-color;
      width: 7px;
      position: absolute;
      margin: 1.5px;
    }
  }

  .map-legend-type {
    min-width: 16px;

    .map-layer-color-square {
      width: $map-layer-color-square-size;
      height: $map-layer-color-square-size;
      border: $map-layer-color-square-border-width solid transparent;
      border-radius: $map-layer-color-square-border-radius;
      display: block;
      margin: 0 2px;
    }

    .map-layer-color-line {
      width: $map-layer-color-line-width;
      height: $map-layer-color-line-height;
      border-top: $map-layer-color-square-border-width solid transparent;
      border-bottom: $map-layer-color-square-border-width solid transparent;
      display: block;
      margin: 0 2px;
    }

    .map-layer-marker {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      overflow: hidden;

      .mat-icon {
        width: 10px;
        height: 10px;
        line-height: 10px;
      }
    }
  }

  &:last-child {
    margin-bottom: -($map-legend-panel-padding);
  }

  .mat-list-item-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0 2px 0 2px !important;
    position: relative;
    height: inherit;
  }
}

.dynamic-field-checkbox-container {
  .mat-checkbox-layout {
    .mat-checkbox-inner-container {
      width: 14px;
      height: 14px;

      .mat-checkbox-frame {
        transition: none;
        border-width: 1.5px;
      }

      .mat-checkbox-background {
        border: 1.5px solid #A0ADBB;
        background: none;
        transition: none;
      }
    }

    .mat-checkbox-label {
      font-size: 11px;
      font-weight: bold;
      color: $map-controls-accent-color;
    }
  }
}

.mat-checkbox-disabled {
  .mat-checkbox-layout {
    .mat-checkbox-inner-container {
      .mat-checkbox-background {
        border: 1.5px solid $eagle-eye-light-blue !important;
        background: #EBEFF5 !important;
      }
    }

    .mat-checkbox-label {
      color: $eagle-eye-light-blue;
    }
  }
}

.mat-checkbox-checked.mat-accent {
  .mat-checkbox-background {
    background: $eagle-eye-highlight-blue !important;
    border: none !important;
  }
}

.mat-slide-toggle {
  .mat-slide-toggle-bar {
    width: 24px !important;
    height: 9px !important;
    border-radius: 8px !important;
  }

  .mat-slide-toggle-ripple {
    top: calc(50% - 16px) !important;
    left: calc(50% - 18px) !important;
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
  }

  .mat-slide-toggle-thumb {
    height: 14px !important;
    width: 14px !important;
    border-radius: 50% !important;
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: #BDE7FF !important;
      width: 24px !important;
      height: 9px !important;
      border-radius: 8px !important;

      .mat-slide-toggle-thumb-container {
        margin-left: -6px;

        .mat-slide-toggle-thumb {
          background-color: #4AADE5 !important;
        }
      }
    }

    .mat-ripple-element {
      background-color: #4AADE5 !important;
    }
  }
}

.remove-button {
  background: $eagle-eye-highlight-blue !important;
  height: 16px !important;
  width: 17px !important;
  padding: 2px 2px 0 0 !important;

  .mat-icon {
    font-size: 12px !important;
  }
}

.expand-button {
  .mat-icon-button {
    .mat-button-ripple {
      border-radius: 0;
    }
  }
}

.otp-slider {
  .mat-slider-thumb, .mat-slider-track-fill {
    background-color: #47B0E7 !important;
  }
}
