@import 'ee-colors';
@import 'sizes';
@import 'base';

@import 'mat-overrides';
@import 'mat-theme';
@import 'mapbox-overrides';
@import 'mixins';
@import 'font-awesome';
@import 'tooltips';
@import 'tables';
@import 'highcharts';
@import 'info-dialog';
@import 'notification-dialog';
@import 'vision/vision-styles';

@import '~@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import 'perfect-scrollbar-override';
@import '~dragula/dist/dragula.css';
@import "~intro.js/introjs.css";
@import 'intro-js-overrides';
@import '~highcharts/css/highcharts.scss';
@import 'tinymce-overrides';

@import 'mobile/login-page';

$flag-icon-css-path: '~flag-icon-css/flags';
@import '~flag-icon-css/sass/flag-icons';

@import '~codemirror/lib/codemirror';