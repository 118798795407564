$fa-font-path:        "~@fortawesome/fontawesome-pro/webfonts";

@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import '~@fortawesome/fontawesome-pro/scss/duotone';
@import '~@fortawesome/fontawesome-pro/scss/duotone-icons';

.mat-icon.fa, .mat-icon.fal, .mat-icon.far, .mat-icon.fas {
  font-size: 24px;
  text-align: center;
}
