.gu-mirror .vision-add-new-section {
  display: none;
}

.vision-add-new-section {
  border: 2px solid rgba(46, 72, 102, 0.1);
  border-radius: 4px;
  padding: 12px;
  margin: 4px -2px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: rgba(46, 72, 102, 0.4);
  font-family: 'Nunito', sans-serif;
  cursor: pointer;
  transition: all 0.25s ease;
  margin-bottom: 16px;

  &.selected {
    border-color: rgba(255, 165, 0, 0.8);
    color: #FFA500;
    box-shadow: 0 0 8px rgba(255, 165, 0, 0.25);
  }

  &.no-bottom-margin {
    margin-bottom: 0;
  }

  &.margin-top {
    margin-top: 16px;
  }

  .mat-icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }

  &:hover:not(.selected) {
    border-color: rgba(46, 72, 102, 0.4);
    color: rgba(46, 72, 102, 0.8);
  }
}

.vision-section-container, .vision-component-container {

  .drag-handle {
    width: 0;
    height: 12px;
    vertical-align: top;
    color: #798A9D;
    font-size: 12px;
    padding: 6px;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    cursor: move;
  }

  .section-buttons {
    transition: all 0.25s ease;
    width: 18px;
    height: 18px;
    visibility: hidden;
    opacity: 0;
    margin: 0 1px;

    &.margin-right {
      margin-right: 8px;
    }

    .mat-icon {
      width: 18px;
      height: 18px;
      vertical-align: top;
      color: #798A9D;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &.edit {
    transition: all 0.25s ease;

    .drag-handle {
      width: 12px;
      visibility: visible;
      opacity: 1;
    }
  }
}

.vision-section-container {
  border: 1px solid transparent;
  margin: -1px;
  border-radius: 4px;
  background-color: transparent;
  padding-top: 0;

  .edit-button {
    transition: all 0.25s ease;
    overflow: hidden;
    width: 0;
    height: 24px;
    visibility: hidden;
    opacity: 0;

    .mat-icon {
      width: 12px;
      height: 12px;
      vertical-align: top;
      color: #798A9D;
      font-size: 12px;
    }
  }

  h3 {
    margin: 0 0 8px 0;
    font-family: 'Nunito', sans-serif !important;
    font-size: 18px;
    font-weight: 700;
    color: #CF003B;
  }

  &.edit {
    transition: all 0.25s ease;
    background-color: rgba(46, 72, 102, 0.1);

    > .edit-panel {
      padding-top: 8px;
    }

    &:hover {
      > div > .edit-button {
        width: 24px;
      }

      > div > .section-buttons, > div > .edit-button {
        visibility: visible;

        &:not([disabled]) {
          opacity: 1;
        }

        &[disabled] {
          opacity: 0.25;
        }
      }
    }
  }
}

.vision-component-container {
  border: 1px solid transparent;
  margin: -1px;
  border-radius: 4px;
  background-color: transparent;
  position: relative;

  .section-buttons .mat-icon {
    line-height: 18px;
  }

  .drag-handle {
    padding: 3px 6px;
  }

  .component {
    background-color: white;
    height: calc(100% - 24px - 2px);
    width: 100%;
    display: block;
    border-radius: 4px;
  }

  .component-caption {
    background-color: white;
    font-size: 11px;
    font-style: italic;
    text-align: center;
    padding: 12px 0;
    color: #71869c;
    width: 100%;

    span {
      width: 100%;
    }

    &.map {
      span {
        width: 515px;
        display: block;
      }
    }
  }

  .text {
    img {
      max-width: 100%;
      height: auto;
    }

    p {
      margin-block-start: 0;
    }
  }

  &.hide {
    border: none;
  }

  &.bottom-margin {
    margin-bottom: 16px;
  }

  .edit-panel {
    transition: all 0.25s ease;
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin-bottom: 0;

    h5 {
      margin: 0;
      font-size: 9px;
      font-weight: bold;
      line-height: 18px;
      color: #798A9D;
    }
  }

  &.map {
    width: 515px;
  }

  &.edit {
    background-color: rgba(46, 72, 102, 0.1);

    .edit-panel {
      visibility: visible;
      opacity: 1;
      height: 18px;
      margin-bottom: 2px;
    }

    &:hover {
      > div > .section-buttons {
        visibility: visible;

        &:not([disabled]) {
          opacity: 1;
        }

        &[disabled] {
          opacity: 0.25;
        }
      }
    }
  }
}
