@import 'ee-colors';
@import 'sizes';
@import 'admin/colors';

.list-container.cdk-virtual-scroll-viewport {
  .cdk-virtual-scroll-content-wrapper {
    width: 100%;
    min-width: 960px;
  }
}

.vision-new-component-accordion {
  .mat-expansion-panel {
    border-radius: 0 !important;

    &:last-child .mat-expansion-panel-header {
      border-bottom: 1px solid #DFE6EE;
    }

    &:not(:first-child) .mat-expansion-panel-header {
      border-top: 1px solid #DFE6EE;
    }

    .mat-expansion-panel-header {
      &.mat-expanded {
        border-bottom: 1px solid #DFE6EE;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0 !important;
      }
    }
  }
}

.vision-accordion {
  .mat-expansion-panel {
    overflow: visible;

    .mat-expansion-panel-content {
      overflow: hidden;

      .table-scrollbar-container {
        position: relative;
        overflow: auto;
      }

      .mat-expansion-panel-body {
        padding: 16px 0 !important;
      }

      .mat-icon {
        height: inherit !important;
        width: inherit !important;
        margin-right: 2px !important;
      }
    }
  }
}

.mat-expansion-panel.cluster-item {
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }
}

.expand-item.mat-accordion {
  .mat-expansion-panel-content {
    font: unset;
    font-family: 'Open Sans', sans-serif !important;

    .mat-expansion-panel-body {
      padding: 0 24px;
    }
  }
}

.mat-tab-group.admin {
  .mat-tab-header {
    border-bottom: none;
    width: calc(100% - 100px);
  }
}

.mat-tab-group.vision-detail-tabs {
  .mat-tab-header {
    padding: 0 24px;
    border-bottom: none;

    .mat-tab-header-pagination {
      box-shadow: none !important;
    }

    .mat-tab-header-pagination {
      &.mat-tab-header-pagination-disabled {
        .mat-tab-header-pagination-chevron {
          border-color: rgba($admin-button-blue, 0.3);
        }
      }

      &:not(.mat-tab-header-pagination-disabled) {
        .mat-tab-header-pagination-chevron {
          border-color: $admin-button-blue;
        }
      }
    }
  }

  .mat-tab-labels {
    .mat-tab-label {
      &:not(:last-child)::after {
        font-family: 'Font Awesome 6 Pro', sans-serif;
        content: '\f324';
        position: relative;
        left: 20px;
        height: 100%;
        width: 24px;
        color: #DFE6EE;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      &.hidden {
        display: none;
      }
    }
  }
}

.vision-dialog-input {
  width: 100%;

  &:not(:first-child) {
    margin-top: 12px;
  }

  .label {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .mat-form-field {
    width: 100%;

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-infix {
        width: 100%;
        border-top: none;
        display: flex;
        flex-direction: row;

        input, textarea, mat-select {
          width: 100%;
          font-family: 'Open Sans', Arial, sans-serif;
          font-size: 12px;
          background: #FFFFFF;
          border: 1px solid rgba(136, 152, 170, 0.4);
          box-sizing: border-box;
          border-radius: 4px;
          padding-left: 8px;
          padding-right: 8px;
        }

        input {
          height: 32px;
          line-height: 32px;
        }

        textarea {
          height: 128px !important;
          padding-top: 6px;
          padding-bottom: 6px;
          line-height: 1.5;
          resize: none;
        }

        mat-select {
          height: 32px;
          line-height: 32px;
        }
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }
}

.mat-form-field.vision-input {
  border: 1px solid $content-fields-border-edit-color;
  padding: 0 $map-ref-search-padding-h;
  border-radius: 4px;
  height: 32px;
  background-color: white;
  box-sizing: border-box;

  &.ng-invalid {
    border-color: #f44336;
  }

  &.sub-heading-1 input {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #1D4289 !important;
  }

  &.sub-heading-2 input {
    font-family: 'Nunito', sans-serif !important;
    font-size: 12px !important;
    font-weight: bold !important;;
    font-style: italic !important;
    color: #47B0E7 !important;
  }

  input {
    vertical-align: middle;
  }

  .mat-form-field-wrapper {
    width: 100%;
    padding-bottom: 0 !important;

    .mat-form-field-infix {
      width: 100%;
      border-top: none;

      input {
        color: $map-ref-search-text-color;
        font-size: 11px;
        font-family: 'Open Sans', sans-serif;
        line-height: 20px;
        margin-top: -3px;
      }

      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          margin-top: -1px;
          font-size: 11px;
          font-family: 'Open Sans', sans-serif !important;
          line-height: 32px;
          color: $map-ref-search-placeholder-color !important;
        }
      }
    }

    .mat-form-field-prefix {
      padding-right: $map-search-button-padding-h;

      .mat-icon {
        color: $map-ref-search-border-color !important;
      }
    }

    .mat-form-field-suffix, .mat-form-field-prefix {
      cursor: pointer;

      .mat-icon {
        position: relative;
        color: $eagle-eye-light-blue;
        font-size: 13px;
        opacity: 0.6;
      }
    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.vision-filters.mat-form-field {

  &.mat-focused {
    .mat-form-field-wrapper {
      border: 1px solid #4aade5 !important;
      box-shadow: 0 0 4px rgba(74, 173, 229, 0.8);
    }
  }

  .mat-form-field-wrapper {
    padding: 0;
    background-color: $content-fields-bg-edit-color;
    border: 1px solid $content-fields-border-edit-color;
    border-radius: 3px;
    height: 30px;

    .mat-form-field-infix {
      border-top: none;
      font-size: 11px;
      width: auto;
      padding: 9px 4px;

      .mat-select {
        font-family: 'Nunito', sans-serif !important;
        line-height: 15px;
        padding: 0 4px;
        width: calc(100% - 8px);

        .mat-select-trigger {
          width: 100%;
          display: inline-block;
          vertical-align: top;

          .mat-select-value {
            max-width: none;
            width: calc(100% - 18px);
            display: inline-block;
          }

          .mat-select-arrow-wrapper {
            width: 18px;
            display: inline-block;
            vertical-align: top;
            margin-top: 6px;
          }
        }
      }

      input {
        line-height: 15px;
        font-family: 'Nunito', sans-serif !important;
        padding: 0 8px;
        width: calc(100% - 28px);
      }
    }

    .mat-form-field-suffix {
      position: absolute;
      bottom: 5px;
      right: 4px;
    }

    .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}
